<template>
  <header>
    <transition
      name="fade"
      mode="out-in"
    >
      <v-progress-linear
        :active="isLoading"
        :indeterminate="true"
        md12
        color="primary"
        class="ma-0"
        height="4"
      />
    </transition>

    <v-app-bar
      id="app-bar"
      absolute
      app
      color="primary"
      flat
      :height="50"
      class="my-0 py-0"
    >
      <router-link
        class="d-flex justify-center align-center flex-shrink-0"
        :to="{ name: 'dashboard' }"
      >
        <img
          :src="require('@/assets/images/logo-odevia-white.png')"
          alt=""
          class="mr-2"
          height="25px"
        >

        <span class="ml-4 mt-2 white--text font-italic font-weight-light">
          {{ $parameter('CUSTOMER_NAME') }}
        </span>

        <component
          :is="require('@/assets/icons/crossroad.svg').default"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </router-link>

      <v-toolbar-title
        v-if="undefined !== $route.meta.title"
        class="hidden-sm-and-down font-weight-light"
        v-text="$t($route.meta.title, $route.meta.args)"
      />
      <v-toolbar-title
        v-else
        class="hidden-sm-and-down font-weight-light"
        v-text="$store.state.page.title"
      />

      <v-tabs
        ref="tabs"
        :value="selected.menu"
        background-color="primary"
        active-class="font-weight-bold"
        centered
        dark
        hide-slider
        @change="(index) => onMenuChange(index, null)"
      >
        <template
          v-for="(item, i) in menuItems"
        >
          <v-tab
            v-if="item.children === undefined"
            :key="`nav-item-${i}`"
          >
            <v-icon
              class="mr-2"
            >
              {{ item.icon }}
            </v-icon>
            {{ $t(item.title) }}
          </v-tab>

          <v-menu
            v-else
            :key="`nav-item-${i}`"
            bottom
            offset-y
            origin="top"
            transition="slide-y-transition"
            open-on-hover
            tile
            class="pt-n5"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-tab
                :key="item.name"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  class="mr-2"
                >
                  {{ item.icon }}
                </v-icon>
                {{ $t(item.title) }}
              </v-tab>
            </template>

            <v-tabs
              :value="selected.submenu[i]"
              background-color="primary"
              hide-slider
              vertical
              optional
              dark
              @change="(index) => onMenuChange(i, index)"
            >
              <v-tab
                v-for="(child, j) in item.children"
                :key="j"
                :value="j"
                :class="{ 'v-tab--active': selected.submenu[i] === j }"
              >
                <v-icon v-if="child.icon">
                  {{ child.icon }}
                </v-icon>
                {{ $t(child.title) }}
              </v-tab>
            </v-tabs>
          </v-menu>
        </template>
      </v-tabs>

      <defects
        v-if="$parameter('TRAFFIC_CONTROL_ENABLE')"
        @hook:mounted="onIconLoaded"
      />
      <notifications @hook:mounted="onIconLoaded" />
      <account @hook:mounted="onIconLoaded" />
    </v-app-bar>
  </header>
</template>

<script>
  import { mapState } from 'vuex';
  import { cloneDeep } from 'lodash';

  export default {
    components: {
      Defects: () => import('@/views/admin/components/core/Defects'),
      Notifications: () => import('@/views/admin/components/core/Notifications'),
      Account: () => import('@/views/admin/components/core/Account'),
    },

    data: () => ({
      selected: {
        menu: null,
        submenu: {},
      },
    }),

    computed: {
      ...mapState('notification', ['notifications']),
      ...mapState('loading', ['loading']),
      isLoading () {
        return this.loading;
      },
      hideSlider () {
        if (this.$route.meta.menu !== undefined) {
          return !this.menuItems.find(item => item.name === this.$route.meta.menu);
        }

        return true;
      },
      menuItems () {
        const menuItems = [
          {
            icon: 'mdi-view-dashboard',
            title: 'messages.views.admin.components.core.appBar.items.dashboard.title',
            route: {
              name: 'dashboard',
            },
            name: 'dashboard',
          },
          {
            icon: 'mdi-traffic-light',
            title: 'messages.views.admin.components.core.appBar.items.regulation.title',
            opened: false,
            name: 'regulation',
            disabled: !this.$parameter('TRAFFIC_CONTROL_ENABLE'),
            children: [
              {
                title: 'messages.views.admin.components.core.appBar.items.regulation.children.timelines',
                route: {
                  name: 'regulation_timelines',
                },
                name: 'regulation_timelines',
              },
              {
                title: 'messages.views.admin.components.core.appBar.items.regulation.children.smartway',
                route: {
                  name: 'regulation_smartway',
                },
                name: 'regulation_smartway',
              },
            ],
          },
          {
            icon: 'mdi-bullhorn-outline',
            title: 'messages.views.admin.components.core.appBar.items.information.title',
            route: {
              name: 'information',
            },
            name: 'information',
            disabled: !this.$parameter('PUBLIC_DASHBOARD_ENABLE') || !this.$parameter('PUBLIC_DASHBOARD_DISPLAY_INFORMATIONS'),
          },
          {
            icon: 'mdi-cog',
            title: 'messages.views.admin.components.core.appBar.items.configuration.title',
            opened: false,
            name: 'configuration',
            disabled: !this.$role('ROLE_ADMIN'),
            children: [
              {
                title: 'messages.views.admin.components.core.appBar.items.configuration.children.stations',
                route: {
                  name: 'configuration_stations',
                },
                name: 'configuration_stations',
                disabled: !this.$parameter('TRAFFIC_CONTROL_ENABLE'),
              },
              {
                title: 'messages.views.admin.components.core.appBar.items.configuration.children.parameters',
                route: {
                  name: 'configuration_parameters',
                },
                name: 'configuration_parameters',
              },
              {
                title: 'messages.views.admin.components.core.appBar.items.configuration.children.users',
                route: {
                  name: 'configuration_users',
                },
                name: 'configuration_users',
              },
              {
                title: 'messages.views.admin.components.core.appBar.items.configuration.children.logbook',
                route: {
                  name: 'configuration_logbook',
                },
                name: 'configuration_logbook',
              },
              {
                title: 'messages.views.admin.components.core.appBar.items.configuration.children.maintenance',
                route: {
                  name: 'configuration_maintenance',
                },
                name: 'configuration_maintenance',
              },
            ],
          },
        ];

        const filteredMenuItems = [];
        for (const menuItem of menuItems) {
          const clonedMenuItem = cloneDeep(menuItem);
          if (!clonedMenuItem.disabled) {
            if (clonedMenuItem.children !== undefined) {
              clonedMenuItem.children = clonedMenuItem.children.filter(child => !child.disabled);
            }

            filteredMenuItems.push(clonedMenuItem);
          }
        }

        return filteredMenuItems;
      },
    },

    created () {
      this.selectMenuItemFromRoute();
    },

    mounted () {
      this.$router.afterEach((to, from) => {
        this.selectMenuItemFromRoute();
      });
    },

    methods: {
      onIconLoaded () {
        this.$refs.tabs.onResize();
      },
      onMenuChange (menu, submenu) {
        if (menu !== undefined && submenu !== undefined) {
          this.selectMenuItem({
            menu: menu,
            submenu: submenu,
          });
        }
      },
      selectMenuItem (item) {
        if (item.menu !== this.selected.menu || item.submenu !== this.selected.submenu[item.menu]) {
          for (const i of Object.keys(this.selected.submenu)) {
            if (i !== item.menu) {
              this.selected.submenu[i] = null;
            }
          }

          this.selected.menu = item.menu;
          const menu = this.menuItems[this.selected.menu];
          if (menu) {
            if (menu.children === undefined) {
              this.$router.push(menu.route).catch(() => {});
            } else {
              if (item.submenu === null) {
                this.selected.submenu[item.menu] = 0;
              } else {
                this.selected.submenu[item.menu] = item.submenu;
              }

              const submenu = menu.children[this.selected.submenu[item.menu]];
              this.$router.push(submenu.route).catch(() => {});
            }
          }
        }
      },
      selectMenuItemFromRoute () {
        if (this.$route.meta.menu) {
          for (const i in this.menuItems) {
            const menu = this.menuItems[i];
            if (menu.name === this.$route.meta.menu) {
              this.selected.menu = parseInt(i);
              for (const j of Object.keys(this.selected.submenu)) {
                this.selected.submenu[j] = null;
              }

              return;
            }

            if (menu.children !== undefined) {
              for (const j in menu.children) {
                const submenu = menu.children[j];
                if (submenu.name === this.$route.meta.menu) {
                  this.selected.menu = parseInt(i);
                  this.selected.submenu[parseInt(i)] = parseInt(j);

                  return;
                }
              }
            }
          }
        }
      },
    },
  };
</script>

<style scoped lang="sass">
  @import '~vuetify/src/styles/styles'

  .v-toolbar__content
    padding-top: 0 !important
    padding-bottom: 0 !important

  .v-tab
    text-transform: none !important

  .badge-count
    font-size: 0.8em

</style>
